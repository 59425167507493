import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Layout, Select } from 'antd';
import Cookies from 'js-cookie';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { relativeTime } from 'dayjs/locale/fr';

const { Header: AntHeader } = Layout;
const { Option } = Select;

const Header = function ({ pageLogo, pageTitle }) {
  const router = useRouter();
  const screens = useBreakpoint();

  const handleChange = (value) => {
    Cookies.set('NEXT_LOCALE', value);
    router.push(router.asPath, router.asPath, { locale: value });
    window.location.href = router.asPath;
  };

  const getHeaderHeight = () => {
    if (screens.sm) return 80;
    if (screens.md) return 90;
    return 65;
  };

  return (
    <AntHeader
      style={{
        padding: 0,
        background: 'transparent !important',
        width: '100%'
      }}
      id="header"
    >
      <div className="headerContainer" data-cy="header">
        <Image
          src="/logo1.png"
          className="logo1"
          alt="Convention Réseau 2022"
          width={400}
          height={70}
        />
        <Image
          src="/logo2.png"
          alt="Renault Trucks Forever (Forts & Verts)"
          width={150}
          height={73}
        />
      </div>
      <div className="headerContainer2" data-cy="header">
        <Image src="/logo3.png" alt="Renault Trucks" width={100} height={147} />
      </div>
      <div className="headerContainer3" data-cy="header">
        <Image src="/logo3.png" alt="Renault Trucks" width={100} height={147} />
      </div>
      <style jsx>{`
        .headerContainer {
          position: absolute;
          top: ${screens.md ? '48px' : 0};
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          max-width: 720px;
          display: flex;
          align-items: ${screens.md ? 'center' : 'flex-start'};
          flex-direction: ${screens.md ? 'row' : 'column'};
          padding: ${screens.md ? 0 : '16px'};
          justify-content: space-between;
        }

        .headerContainer2 {
          position: absolute;
          top: ${screens.md ? '48px' : '60px'};
          left: ${screens.md ? '50%' : 'initial'};
          right: ${screens.md ? 'initial' : '0'};
          transform: ${screens.md ? 'translateX(-50%)' : 'intial'};
          margin-left: ${screens.md ? 'calc(360px + 100px)' : '0'};
          padding: ${screens.md ? 0 : '16px'};
          max-width: ${screens.md ? 'none' : '80px'};
          display: ${screens.xl ? 'inline-block' : 'none'};
        }
        .headerContainer3 {
          position: absolute;
          top: ${screens.md ? '48px' : '60px'};
          left: ${screens.md ? '50%' : 'initial'};
          right: ${screens.md ? 'initial' : '0'};
          transform: ${screens.md ? 'translateX(-50%)' : 'intial'};
          margin-left: ${screens.md ? 'calc(360px + 100px)' : '0'};
          padding: ${screens.md ? 0 : '16px'};
          max-width: ${screens.md ? 'none' : '80px'};
          display: ${screens.md ? 'none' : 'ineline-block'};
        }
        
        .logoContainer {
          width: 100%;
          height: ${getHeaderHeight()}px;
          padding: 8px 0;
          margin-right: ${screens.md ? 24 : 16}px;
          max-width: ${screens.xs ? '35vw' : '250px'};
        }
        .logoSubContainer {
          position: relative;
          max-width: 100%;
          height: 100%;
        }
        .logoSubContainer img {
          max-height: ${getHeaderHeight() - 16}px;
        }
        .subHeader {
          flex-grow: 1;
        }
        .title {
          font-size: 16px;
          line-height: 1.2;
          margin-left: 16px;
          margin: 0;
          padding: 8px 24px;
        }
        .subTitle {
          font-size: 20px;
          line-height: 1.2;
          margin-left: 16px;
          margin: 0;
        }
      `}</style>
    </AntHeader>
  );
};

export default Header;
